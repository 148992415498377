/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customComponents/Navbars/DashboardNavbar";
import Footer from "customComponents/Footer";
import ComplexStatisticsCard from "customComponents/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import Employees from "layouts/dashboard/components/Employees";
import { formatDate } from "../../utils";

function Dashboard() {
  const curDate = useMemo(() => {
    return formatDate(new Date(), "YYYY-MM-DD");
    // return moment().subtract(2, "days").format("YYYY-MM-DD");
  }, []);

  const [presentEmployees, setPresentEmployees] = useState(0);
  const [absentEmployees, setAbsentEmployees] = useState(0);
  const [lateEmployees, setLateEmployees] = useState(0);

  const totalEmployees = useMemo(() => {
    return presentEmployees + absentEmployees;
  }, [presentEmployees, absentEmployees]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="people"
                title="Employees"
                count={totalEmployees}
                percentage={{
                  color: "success",
                  // amount: "+55%",
                  label: "Total Employees",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="person"
                title="Today's Present"
                count={presentEmployees}
                percentage={{
                  color: "success",
                  amount:
                    (totalEmployees > 0
                      ? ((presentEmployees / totalEmployees) * 100).toFixed(0)
                      : "0") + "%",
                  label: "of total employees",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="elderly"
                title="Late arrivals"
                count={lateEmployees}
                percentage={{
                  color: "success",
                  amount:
                    (presentEmployees > 0
                      ? ((lateEmployees / presentEmployees) * 100).toFixed(0)
                      : "0") + "%",
                  label: "of employees present",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_off"
                title="Today's Absent"
                count={absentEmployees}
                percentage={{
                  color: "success",
                  // amount: ((totalEmployees > 0) ? (absentEmployees / totalEmployees * 100).toFixed(0): '0'),
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Employees
                title="Employees Present"
                type="full"
                filter={{ type: "present", to: curDate, from: curDate }}
                onDataLoaded={(length) => {
                  if (presentEmployees !== length) {
                    setPresentEmployees(length);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Employees
                title="Late Arrivals"
                type="full"
                filter={{ type: "late", to: curDate, from: curDate }}
                onDataLoaded={(length) => {
                  if (lateEmployees !== length) {
                    setLateEmployees(length);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Employees
                title="Employees Absent"
                filter={{ type: "absent", to: curDate, from: curDate }}
                onDataLoaded={(length) => {
                  if (absentEmployees !== length) {
                    setAbsentEmployees(length);
                  }
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer style={{ marginTop: "800px" }} />
    </DashboardLayout>
  );
}

export default Dashboard;
