import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import MDBox from "components/MDBox";

import { Controller, useForm } from "react-hook-form";

import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import PropTypes from "prop-types";

function BulkImportDialog({ open, onClose, onSubmit }) {
  const { control, handleSubmit, reset, setValue } = useForm();

  const [devicesOptions, setDevicesOptions] = useState([]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleFormSubmit = (data) => {
    const { file, fileName } = data;

    onSubmit &&
      onSubmit({
        file,
      });

    // reset();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormContainer
        control={control}
        // defaultValue={defaultValues}
        onSuccess={handleSubmit(handleFormSubmit)}
      >
        <DialogTitle>Bulk Import</DialogTitle>

        <DialogContent>
          <DialogContentText>Please upload a CSV file to import.</DialogContentText>

          <TextFieldElement
            control={control}
            margin="dense"
            name="fileName"
            label="CSV File"
            type="file"
            fullWidth
            variant="standard"
            onChange={(e) => {
              console.log("e.target.files[0].name", e.target.files[0]);
              setValue("file", e.target.files[0]);
              // +        setValue("photoIdPath", e.target.files[0].name);
              // +        field.onChange(e.target.files);
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
BulkImportDialog.defaultProps = {
  open: false,
  onClose: null,
  onSubmit: null,
};

// Typechecking props for the DataTable
BulkImportDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default BulkImportDialog;
