/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import APIDataTable from "customComponents/Tables/APIDataTable";
import { useAlert } from "customComponents/Alert";

import PropTypes from "prop-types";
import { getEmployeesAvailabile } from "helper/index";
import { formatDate, isObjectEqual } from "../../../../utils";
import { deviceLocation } from "../../../../enums";

function Employees({ type, title, filter, onDataLoaded }) {
  const alert = useAlert();

  const [resourcesData, setResourcesData] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [searchFilter, setSearchFilter] = useState({});

  const columns =
    type === "full"
      ? [
          { Header: "name", accessor: "name", align: "left" },
          { Header: "userId", accessor: "userId", align: "center" },
          { Header: "recordTime", accessor: "recordTime", align: "center" },
          { Header: "location", accessor: "location", align: "center" },
          { Header: "shift", accessor: "shift", align: "right" },
        ]
      : [
          { Header: "name", accessor: "name", align: "left" },
          { Header: "userId", accessor: "userId", align: "center" },
          { Header: "shift", accessor: "shift", align: "right" },
        ];

  useEffect(() => {
    fetchData();
  }, [page, limit, searchFilter]);

  const fetchData = useCallback(async () => {
    try {
      const { search, ...rest } = searchFilter;

      let payload = { page, limit, sortBy: "name:asc", ...filter, ...rest };

      if (search && search !== "") {
        payload["name"] = search;
      }

      const res = await getEmployeesAvailabile(payload);

      const { results, totalPages, totalResults } = res.data;

      const formattedData = formatData(results);

      setResourcesData(formattedData);
      setTotalPages(totalPages);
      setTotalResults(totalResults);

      onDataLoaded && onDataLoaded(totalResults);
    } catch (error) {
      console.log("error", error);

      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  }, [page, limit, searchFilter]);

  const formatData = (res) => {
    return res.map((value, index) => {
      const { name, userId, shift, attendance } = value;

      if (type === "full") {
        const { recordTime, device } = attendance;

        return {
          name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {name}
            </MDTypography>
          ),
          userId: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {userId}
            </MDTypography>
          ),

          recordTime: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {formatDate(recordTime)}
            </MDTypography>
          ),
          location: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {deviceLocation.value(device?.location)}
            </MDTypography>
          ),
          shift: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {shift.title}
            </MDTypography>
          ),
        };
      } else {
        return {
          name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {name}
            </MDTypography>
          ),
          userId: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {userId}
            </MDTypography>
          ),

          shift: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {shift.title}
            </MDTypography>
          ),
        };
      }
    });
  };

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
        <MDBox>
          <MDTypography variant="h6">{title}</MDTypography>
        </MDBox>
      </MDBox>

      <MDBox>
        <APIDataTable
          table={{ rows: resourcesData, columns }}
          showTotalEntries={true}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
          canSearch
          currentPage={page}
          totalPages={totalPages}
          totalResults={totalResults}
          fetchData={(newPage, newLimit, newFilter) => {
            if (newPage !== page) {
              setPage(newPage);
            }

            if (newLimit !== limit) {
              setLimit(newLimit);
            }

            if (!isObjectEqual(newFilter, searchFilter)) {
              if (page !== 1 || newPage !== 1) {
                setPage(1);
              }

              setSearchFilter(newFilter);
            }
          }}
        />
      </MDBox>
    </Card>
  );
}

Employees.defaultProps = {
  title: "Employees",
  filter: {},
  type: "limited",
  onDataLoaded: null,
};

// Typechecking props for the DataTable
Employees.propTypes = {
  title: PropTypes.string,
  filter: PropTypes.object,
  type: PropTypes.string,
  onDataLoaded: PropTypes.func,
};

export default Employees;
