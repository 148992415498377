/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

import moment from "moment";

import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import PageLayout from "customComponents/LayoutContainers/PageLayout";
import Footer from "customComponents/Footer";

import DataTable from "customComponents/Tables/DataTable";
import { useAlert } from "customComponents/Alert";

import { formatDate } from "../../utils";

import { getAllEmployeesReport } from "helper/index";
import config from "../../config";

import AttendenceDialog from "../report/components/attendanceDialog";

function EmployeeAdminReport() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);
  const [dateInfo, setDateInfo] = useState(null);

  const [isLinkExpired, setLinkExpired] = useState(false);

  const { token } = useParams();
  const alert = useAlert();

  const [attendanceDialog, setAttendanceDialog] = useState(null);

  const columns = [
    { Header: "No.", accessor: "no", width: "5%", align: "center" },
    { Header: "Employee Id", accessor: "userId", align: "center" },
    { Header: "Name", accessor: "name", align: "center" },
    { Header: "Shift", accessor: "shift", align: "center" },
    { Header: "Office Time", accessor: "officeTime", align: "center" },
    { Header: "Break Time", accessor: "breakTime", align: "center" },
    { Header: "Total Time", accessor: "totalTime", align: "center" },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const res = await getAllEmployeesReport(
        {
          sortBy: "userId:asc",
          collation: "locale:en_US,numericOrdering:true",
        },
        token
      );

      const { date, employees, ...rest } = res.data;

      const formattedData = formatData(employees, date);

      setAttendanceData(formattedData);

      setDateInfo(date);
      setEmployeeData(rest);
    } catch (error) {
      if (error.response?.status === 401) {
        console.log("link expired");
        setLinkExpired(true);
      } else {
        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    }
  }, []);

  const formatData = (employees, date) => {
    const rows = employees.map((employee, index) => {
      const { userId, name, email, attendances, shift } = employee;

      const curAttendances = attendances && attendances[date] ? attendances[date] : [];
      const { officeTime, breakTime } = calculateOfficeTime(curAttendances);

      return {
        no: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {index + 1}
          </MDTypography>
        ),
        userId: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {userId}
          </MDTypography>
        ),
        name: email ? (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1} ml={0}>
              <MDTypography display="block" variant="button" fontWeight="medium" color="text">
                {name}
              </MDTypography>
              <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
          </MDBox>
        ) : (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {name}
          </MDTypography>
        ),
        shift: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {shift.title}
          </MDTypography>
        ),
        officeTime:
          curAttendances.length > 0 ? (
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              sx={{ cursor: "pointer" }}
              data={JSON.stringify({
                id: userId,
                name,
                date,
                attendances: curAttendances,
              })}
              onClick={openAttendanceDialog}
            >
              {(officeTime / 3600).toFixed(2)}h
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              A
            </MDTypography>
          ),
        breakTime:
          curAttendances.length > 0 ? (
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              sx={{ cursor: "pointer" }}
              data={JSON.stringify({
                id: userId,
                name,
                date,
                attendances: curAttendances,
              })}
              onClick={openAttendanceDialog}
            >
              {(breakTime / 3600).toFixed(2)}h
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              A
            </MDTypography>
          ),
        totalTime:
          curAttendances.length > 0 ? (
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              sx={{ cursor: "pointer" }}
              data={JSON.stringify({
                id: userId,
                name,
                date,
                attendances: curAttendances,
              })}
              onClick={openAttendanceDialog}
            >
              {((officeTime + breakTime) / 3600).toFixed(2)}h
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              A
            </MDTypography>
          ),
      };
    });

    return rows;
  };

  const calculateOfficeTime = (attendances) => {
    let officeTime = 0;
    let breakTime = 0;

    if (attendances.length > 1) {
      const firstAttendance = attendances[0];
      const latestAttendance = attendances[attendances.length - 1];

      const firstRecordTime = moment(firstAttendance.recordTime, "yyyy-MM-DDTHH:mm:ss.SSSZ");
      const latestRecordTime = moment(latestAttendance.recordTime, "yyyy-MM-DDTHH:mm:ss.SSSZ");

      const totalOfficeTime = latestRecordTime.diff(firstRecordTime, "seconds");

      //Admins and office Boys have more check ins and check outs so only calculating their first and last thumb
      if (attendances.length < 30) {
        for (var i = 1; i < attendances.length; i++) {
          const lastAttendance = attendances[i - 1];
          const curAttendance = attendances[i];

          const lastRecordTime = moment(lastAttendance.recordTime, "yyyy-MM-DDTHH:mm:ss.SSSZ");
          const curRecordTime = moment(curAttendance.recordTime, "yyyy-MM-DDTHH:mm:ss.SSSZ");

          const diff = curRecordTime.diff(lastRecordTime, "seconds");

          // if diff is greater than or equal to minimum break time
          if (diff >= config.MINIMUM_BREAK_TIME) {
            if (
              lastAttendance.device.state === "checkOut" &&
              curAttendance.device.state === "checkIn"
            ) {
              breakTime += diff;
            } else if (
              (lastAttendance.device.state === "checkOut" &&
                curAttendance.device.state === "checkOut") ||
              (lastAttendance.device.state === "checkIn" &&
                curAttendance.device.state === "checkIn")
            ) {
              breakTime += config.MINIMUM_BREAK_TIME; // assuming here, user has not checked in so he might have spent MINIMUM_BREAK_TIME outside
            }
          }
        }
      }

      officeTime = totalOfficeTime - breakTime;
    }

    return { officeTime, breakTime };
  };

  const openAttendanceDialog = ({ currentTarget }) => {
    const dataJson = currentTarget.getAttribute("data");
    const data = JSON.parse(dataJson);

    setAttendanceDialog(data);
  };

  const closeAttendanceDialog = () => {
    setAttendanceDialog(null);
  };

  // height="100vh" mx="auto"
  return (
    <PageLayout>
      <MDBox p={3} width="100%">
        {isLinkExpired && (
          <MDBox display="flex" flexDirection="column" flex={1} alignItems="center">
            <MDTypography variant="medium" color="text">
              This link has expired.
            </MDTypography>
          </MDBox>
        )}
        {employeeData && dateInfo && (
          <>
            <MDBox
              display="flex"
              flexDirection="row"
              flex={1}
              alignItems="center"
              justifyContent="left"
            >
              <MDBox display="flex" flexDirection="row" alignItems="center">
                <MDAvatar src={employeeData.image} bgColor="secondary" shadow="xl" />
              </MDBox>

              <MDBox display="flex" flexDirection="column" alignItems="left" pl={2}>
                <MDTypography fontWeight="medium">{employeeData.name}</MDTypography>

                <MDTypography variant="caption" fontWeight="medium">
                  Date: {formatDate(dateInfo, "ll")}
                </MDTypography>
              </MDBox>
            </MDBox>

            <MDBox py={3} display="flex" flexDirection="column" flex={1} alignItems="center">
              <DataTable
                table={{ rows: attendanceData, columns }}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
                entriesPerPage={false}
                defaultPageSize={attendanceData.length > 0 ? attendanceData.length : 10}
              />
            </MDBox>
          </>
        )}

        <Footer />
      </MDBox>

      {attendanceDialog && (
        <AttendenceDialog
          open={Boolean(attendanceDialog)}
          data={attendanceDialog}
          onClose={closeAttendanceDialog}
        />
      )}
    </PageLayout>
  );
}

export default EmployeeAdminReport;
