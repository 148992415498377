import React, { useEffect, useCallback, useState } from "react";

import {
  Button,
  TextField,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "customComponents/Tables/DataTable";

import PropTypes from "prop-types";
import { formatDate } from "../../../../utils";
import { deviceLocation, deviceState, deviceType } from "../../../../enums";

function AttendenceDialog({ open, data, onClose }) {
  const { name, date, attendances } = data;

  const columns = [
    { Header: "recordTime", accessor: "recordTime", align: "center" },
    { Header: "state", accessor: "state", align: "center" },
    { Header: "location", accessor: "location", align: "center" },
    { Header: "type", accessor: "type", align: "center" },
  ];

  const rows = attendances.map((attendance) => {
    const { recordTime, device, id } = attendance;

    return {
      recordTime: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {formatDate(recordTime)}
        </MDTypography>
      ),
      state: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {deviceState.value(device?.state)}
        </MDTypography>
      ),
      location: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {deviceLocation.value(device?.location)}
        </MDTypography>
      ),
      type: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {deviceType.value(device?.type)}
        </MDTypography>
      ),
    };
  });

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <MDBox display="flex" flexDirection="column" flex={1} alignItems="center">
          <MDTypography variant="block">{name}</MDTypography>
          <MDTypography variant="caption" color="text">
            {formatDate(date, "ll")}
          </MDTypography>
        </MDBox>
      </DialogTitle>
      <DialogContent>
        <DataTable
          table={{ rows, columns }}
          showTotalEntries={true}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
AttendenceDialog.defaultProps = {
  open: false,
  data: {},
  onClose: null,
};

// Typechecking props for the DataTable
AttendenceDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default AttendenceDialog;
