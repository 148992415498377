import React, { useEffect } from "react";

import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { useForm } from "react-hook-form";

import { FormContainer, TextFieldElement, TimePickerElement } from "react-hook-form-mui";

import PropTypes from "prop-types";

import MDInput from "components/MDInput";

function AddDialog({ open, id, selectedType, defaultValues, onClose, onSubmit }) {
  const { control, handleSubmit, getValues, reset, setValue } = useForm();

  const handleClose = () => {
    onClose && onClose();
  };

  useEffect(() => {
    // Set the default values when the component mounts
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleFormSubmit = (data) => {
    if (selectedType === "add") {
      onSubmit && onSubmit({ ...data, selectedType });
    } else {
      const { title, startTime, duration, graceTime } = data;

      let updatedData = {};

      if (title && title !== defaultValues.title) {
        updatedData["title"] = title;
      }

      if (startTime && startTime !== defaultValues.startTime) {
        updatedData["startTime"] = startTime;
      }

      if (duration && duration !== defaultValues.duration) {
        updatedData["duration"] = duration;
      }
      if (graceTime && graceTime !== defaultValues.graceTime) {
        updatedData["graceTime"] = graceTime;
      }

      onSubmit && onSubmit({ ...updatedData, selectedType, id });
    }

    // reset();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <FormContainer
        control={control}
        defaultValue={defaultValues}
        onSuccess={handleSubmit(handleFormSubmit)}
      >
        <DialogTitle>{selectedType === "add" ? "Add Shift" : "Edit Shift"}</DialogTitle>

        <DialogContent>
          <DialogContentText>Please enter the required information.</DialogContentText>
          <TextFieldElement
            control={control}
            margin="dense"
            name="title"
            label="Shift title"
            type="text"
            fullWidth
            variant="standard"
            required
          />

          <TextFieldElement
            control={control}
            InputLabelProps={{ shrink: true }}
            margin="dense"
            name="startTime"
            label="Start Time"
            type="time"
            fullWidth
            variant="standard"
            required
          />

          <TextFieldElement
            control={control}
            margin="dense"
            name="duration"
            label="Duration (In hours)"
            type="number"
            fullWidth
            variant="standard"
            required
          />

          <TextFieldElement
            control={control}
            margin="dense"
            name="graceTime"
            label="Grace Time (In minutes)"
            type="number"
            fullWidth
            variant="standard"
            required
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
AddDialog.defaultProps = {
  open: false,
  userId: null,
  defaultValues: {},
  onClose: null,
  onSubmit: null,
};

// Typechecking props for the DataTable
AddDialog.propTypes = {
  open: PropTypes.bool,
  selectedType: PropTypes.oneOf(["add", "edit"]).isRequired,
  userId: PropTypes.string,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddDialog;
