/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

import moment from "moment";

import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import PageLayout from "customComponents/LayoutContainers/PageLayout";
import Footer from "customComponents/Footer";

import DataTable from "customComponents/Tables/DataTable";
import { useAlert } from "customComponents/Alert";

import { formatDate } from "../../utils";
import { deviceLocation, deviceState, deviceType } from "../../enums";

import { getIndividualEmployeeReport } from "helper/index";
import config from "../../config";

function EmployeeReport() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);
  const [dateInfo, setDateInfo] = useState(null);

  const [isLinkExpired, setLinkExpired] = useState(false);

  const { token } = useParams();
  const alert = useAlert();

  const columns = [
    { Header: "No.", accessor: "no", width: "10%", align: "center" },
    { Header: "recordTime", accessor: "recordTime", width: "20%", align: "center" },
    { Header: "state", accessor: "state", align: "center" },
    { Header: "location", accessor: "location", align: "center" },
    { Header: "type", accessor: "type", align: "center" },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const res = await getIndividualEmployeeReport(token);

      const { date, attendances, ...rest } = res.data;

      const formattedData = formatData(attendances);

      const { officeTime, breakTime } = calculateOfficeTime(attendances);

      setEmployeeData(rest);
      setDateInfo({ date, officeTime, breakTime });
      setAttendanceData(formattedData);
    } catch (error) {
      if (error.response?.status === 401) {
        console.log("link expired");
        setLinkExpired(true);
      } else {
        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    }
  }, []);

  const formatData = (attendances) => {
    const rows = attendances.map((attendance, index) => {
      const { recordTime, device } = attendance;

      return {
        no: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {index + 1}
          </MDTypography>
        ),
        recordTime: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {formatDate(recordTime)}
          </MDTypography>
        ),
        state: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {deviceState.value(device?.state)}
          </MDTypography>
        ),
        location: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {deviceLocation.value(device?.location)}
          </MDTypography>
        ),
        type: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {deviceType.value(device?.type)}
          </MDTypography>
        ),
      };
    });

    return rows;
  };

  const calculateOfficeTime = (attendances) => {
    let officeTime = 0;
    let breakTime = 0;

    if (attendances.length > 1) {
      const firstAttendance = attendances[0];
      const latestAttendance = attendances[attendances.length - 1];

      const firstRecordTime = moment(firstAttendance.recordTime, "yyyy-MM-DDTHH:mm:ss.SSSZ");
      const latestRecordTime = moment(latestAttendance.recordTime, "yyyy-MM-DDTHH:mm:ss.SSSZ");

      const totalOfficeTime = latestRecordTime.diff(firstRecordTime, "seconds");

      //Admins and office Boys have more check ins and check outs so only calculating their first and last thumb
      if (attendances.length < 30) {
        for (var i = 1; i < attendances.length; i++) {
          const lastAttendance = attendances[i - 1];
          const curAttendance = attendances[i];

          const lastRecordTime = moment(lastAttendance.recordTime, "yyyy-MM-DDTHH:mm:ss.SSSZ");
          const curRecordTime = moment(curAttendance.recordTime, "yyyy-MM-DDTHH:mm:ss.SSSZ");

          const diff = curRecordTime.diff(lastRecordTime, "seconds");

          // if diff is greater than or equal to minimum break time
          if (diff >= config.MINIMUM_BREAK_TIME) {
            if (
              lastAttendance.device.state === "checkOut" &&
              curAttendance.device.state === "checkIn"
            ) {
              breakTime += diff;
            } else if (
              (lastAttendance.device.state === "checkOut" &&
                curAttendance.device.state === "checkOut") ||
              (lastAttendance.device.state === "checkIn" &&
                curAttendance.device.state === "checkIn")
            ) {
              breakTime += config.MINIMUM_BREAK_TIME; // assuming here, user has not checked in so he might have spent MINIMUM_BREAK_TIME outside
            }
          }
        }
      }

      officeTime = totalOfficeTime - breakTime;
    }

    return { officeTime, breakTime };
  };

  return (
    <PageLayout>
      <MDBox p={3} width="100%" height="100vh" mx="auto">
        {isLinkExpired && (
          <MDBox display="flex" flexDirection="column" flex={1} alignItems="center">
            <MDTypography variant="medium" color="text">
              This link has expired.
            </MDTypography>
          </MDBox>
        )}
        {employeeData && dateInfo && (
          <>
            <MDBox
              display="flex"
              flexDirection="row"
              flex={1}
              alignItems="center"
              justifyContent="left"
            >
              <MDBox display="flex" flexDirection="row" alignItems="center">
                <MDAvatar src={employeeData.image} bgColor="secondary" shadow="xl" />
              </MDBox>

              <MDBox display="flex" flexDirection="column" alignItems="left" pl={2}>
                <MDTypography fontWeight="medium">{employeeData.name}</MDTypography>

                <MDTypography variant="caption" fontWeight="medium">
                  Date: {formatDate(dateInfo.date, "ll")}
                </MDTypography>

                <MDTypography variant="caption" fontWeight="medium">
                  Office Time: {(dateInfo.officeTime / 3600).toFixed(2)}h - Break Time:{" "}
                  {(dateInfo.breakTime / 3600).toFixed(2)}h
                </MDTypography>
              </MDBox>
            </MDBox>

            <MDBox py={3} display="flex" flexDirection="column" flex={1} alignItems="center">
              <DataTable
                table={{ rows: attendanceData, columns }}
                showTotalEntries={true}
                isSorted={false}
                noEndBorder
                entriesPerPage={false}
              />
            </MDBox>
          </>
        )}
      </MDBox>

      <Footer />
    </PageLayout>
  );
}

export default EmployeeReport;
