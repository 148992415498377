const deviceLocation = Object.freeze({
  CLIFTON: "clifton",
  PECHS: "pechs",
  value: (key) => {
    switch (key) {
      case deviceLocation.CLIFTON:
        return "CLIFTON";
      default:
        return "PECHS";
    }
  },
});

const deviceState = Object.freeze({
  CHECKIN: "checkIn",
  CHECKOUT: "checkOut",
  value: (key) => {
    switch (key) {
      case deviceState.CHECKIN:
        return "CHECK IN";
      default:
        return "CHECK OUT";
    }
  },
});

const deviceType = Object.freeze({
  PRIMARY: "primary",
  SECONDARY: "secondary",
  value: (key) => {
    switch (key) {
      case deviceType.PRIMARY:
        return "PRIMARY";
      default:
        return "SECONDARY";
    }
  },
});

module.exports = {
  deviceLocation,
  deviceState,
  deviceType,
};
