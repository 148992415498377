import moment from "moment";

function formatDate(date, format = "lll") {
  return date ? moment(date, "yyyy-MM-DDTHH:mm:ss.SSSZ").format(format) : "n.a.";
}

function formatTime(date, format = "hh:mm a") {
  return date ? moment(date, "HH:mm").format(format) : "n.a.";
}

function isObjectEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export { formatTime, formatDate, isObjectEqual };
