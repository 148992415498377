/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components

import { Button, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Controller, useForm } from "react-hook-form";

import { FormContainer, TextFieldElement, DateTimePickerElement } from "react-hook-form-mui";

import AutocompleteSearch from "customComponents/AutocompleteSearch";

function ReportFilter({ onSubmit }) {
  const defaultValues = {};
  const { control, handleSubmit } = useForm();

  const handleFormSubmit = (data) => {
    const { employees, ...rest } = data;

    let filter = { ...rest };

    if (employees && employees.length > 0) {
      filter["employeeIds"] = (employees ? employees.map((employee) => employee.id) : []).join(",");
    }

    onSubmit && onSubmit(filter);
  };

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={2}>
        <MDTypography variant="h6">Filter</MDTypography>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
        p={3}
        pt={0}
      >
        <FormContainer
          FormProps={{
            style: { display: "flex", flex: 1, flexDirection: "column" },
          }}
          control={control}
          defaultValue={defaultValues}
          onSuccess={handleSubmit(handleFormSubmit)}
        >
          <MDBox display="flex" flexDirection="row" justifyContent="left">
            <TextFieldElement
              InputLabelProps={{ shrink: true }}
              control={control}
              margin="dense"
              name="from"
              label="From"
              type="date"
              //   fullWidth
              variant="standard"
              required
              sx={{ paddingRight: 2, width: "25%" }}
            />

            <TextFieldElement
              InputLabelProps={{ shrink: true }}
              control={control}
              margin="dense"
              name="to"
              label="To"
              type="date"
              //   fullWidth
              variant="standard"
              required
              sx={{ paddingRight: 2, width: "25%" }}
            />

            <Controller
              control={control}
              name="employees"
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <AutocompleteSearch
                  label={"Employee (Optional)"}
                  refreshKey={`${Boolean(defaultValues)}`}
                  value={value}
                  //   fullWidth={false}
                  apiUrl={"/v2/employees"}
                  filter={{}}
                  optionKey={"name"}
                  multiple
                  onOptionSelected={(resp) => {
                    onChange(resp);
                  }}
                />
              )}
            />
          </MDBox>

          <MDBox display="flex" flexDirection="row" justifyContent="right">
            <Button type="submit" sx={{ p: 0 }}>
              Generate Report
            </Button>
          </MDBox>
        </FormContainer>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props
ReportFilter.defaultProps = {
  onSubmit: null,
};

// Typechecking props for the DataTable
ReportFilter.propTypes = {
  onSubmit: PropTypes.func,
};

export default ReportFilter;
