/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardNavbar from "customComponents/Navbars/DashboardNavbar";
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import Footer from "customComponents/Footer";

import ReportFilter from "./components/reportFilter";
import EmployeesReport from "./components/employeesReport";

function Report() {
  const [reportFilter, setReportFilter] = useState(null);

  const handleFilter = (filter) => {
    setReportFilter(filter);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <ReportFilter onSubmit={handleFilter} />
          </Grid>

          {reportFilter && (
            <Grid item xs={12} md={12} lg={12}>
              <EmployeesReport filter={reportFilter} />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Report;
