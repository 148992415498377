/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";
import moment from "moment";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import APIDataTable from "customComponents/Tables/APIDataTable";
import { useAlert } from "customComponents/Alert";

import PropTypes, { array } from "prop-types";
import { getEmployeesReport } from "helper/index";

import downloadCsv from "download-csv";

import AttendenceDialog from "../attendanceDialog";
import Config from "../../../../config";

function ReportData({ filter }) {
  const alert = useAlert();

  const [resourcesData, setResourcesData] = useState({ rows: [], columns: [] });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const [attendanceDialog, setAttendanceDialog] = useState(null);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const fetchData = useCallback(async () => {
    try {
      const payload = { page, limit, sortBy: "name:asc", ...filter };

      const res = await getEmployeesReport(payload);

      const { results, totalPages, totalResults } = res.data;

      const formattedData = formatData(results, filter);

      setTotalPages(totalPages);
      setTotalResults(totalResults);
      setResourcesData(formattedData);
    } catch (error) {
      console.log("error", error);

      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  }, [page, limit, filter]);

  const fetchReportData = useCallback(async () => {
    try {
      const cLimit = totalResults > 0 ? totalResults : 500;

      const payload = { limit: cLimit, sortBy: "name:asc", ...filter };

      const res = await getEmployeesReport(payload);

      const { results } = res.data;

      const formattedData = formatData(results, filter, true);

      return formattedData;
    } catch (error) {
      throw new Error(error);
    }
  }, [filter]);

  const formatData = (res, filter, reportData = false) => {
    const { to, from } = filter;

    const startDate = moment(`${from} 00:00`, "YYYY-MM-DD HH:mm");
    const endDate = moment(`${to} 23:59`, "YYYY-MM-DD HH:mm");

    let iDate = moment(startDate);

    let dates = [];
    let days = [];
    let dateColumns = [];

    do {
      const formattedDate = iDate.format("YYYY-MM-DD");

      dates.push(formattedDate);
      days.push(iDate.day());

      dateColumns.push(
        reportData
          ? {}
          : {
              Header: iDate.format("DD/MM"),
              accessor: formattedDate,
              align: "center",
            }
      );

      iDate.add(1, "days");
    } while (iDate.isBefore(endDate));

    const columns = reportData
      ? []
      : [
          { Header: "name", accessor: "name", width: "10%", align: "left" },
          ...dateColumns,
          { Header: "total", accessor: "total", align: "right" },
        ];

    const rows = res.map((value, index) => {
      const { name, userId, id, attendances } = value;

      const dateTimeKeys = Object.keys(attendances);

      const dateTimeKeyValues = {};

      for (const dateTimeKey of dateTimeKeys) {
        const dateTime = moment(dateTimeKey);
        dateTimeKeyValues[dateTime.format("YYYY-MM-DD")] = dateTimeKey;
      }

      let dateRows = {};

      let totalTime = 0;

      for (const index in dates) {
        const date = dates[index];
        const dayOfWeek = days[index];
        const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

        if (dateTimeKeyValues[date]) {
          const curAttendances = attendances[dateTimeKeyValues[date]];

          let officeTime = 0;
          let breakTime = 0;

          if (curAttendances.length > 1) {
            const firstAttendance = curAttendances[0];
            const latestAttendance = curAttendances[curAttendances.length - 1];

            const firstRecordTime = moment(firstAttendance.recordTime, "yyyy-MM-DDTHH:mm:ss.SSSZ");
            const latestRecordTime = moment(
              latestAttendance.recordTime,
              "yyyy-MM-DDTHH:mm:ss.SSSZ"
            );

            const totalOfficeTime = latestRecordTime.diff(firstRecordTime, "seconds");

            //Admins and office Boys have more check ins and check outs so only calculating their first and last thumb
            if (curAttendances.length < 30) {
              for (var i = 1; i < curAttendances.length; i++) {
                const lastAttendance = curAttendances[i - 1];
                const curAttendance = curAttendances[i];

                const lastRecordTime = moment(
                  lastAttendance.recordTime,
                  "yyyy-MM-DDTHH:mm:ss.SSSZ"
                );
                const curRecordTime = moment(curAttendance.recordTime, "yyyy-MM-DDTHH:mm:ss.SSSZ");

                const diff = curRecordTime.diff(lastRecordTime, "seconds");

                // if diff is greater than or equal to MINIMUM_BREAK_TIME
                if (diff >= Config.MINIMUM_BREAK_TIME) {
                  if (
                    lastAttendance.device.state === "checkOut" &&
                    curAttendance.device.state === "checkIn"
                  ) {
                    breakTime += diff;
                  } else if (
                    (lastAttendance.device.state === "checkOut" &&
                      curAttendance.device.state === "checkOut") ||
                    (lastAttendance.device.state === "checkIn" &&
                      curAttendance.device.state === "checkIn")
                  ) {
                    breakTime += Config.MINIMUM_BREAK_TIME; // assuming here, user has not checked in so he might have spent Config.MINIMUM_BREAK_TIME outside
                  }
                }
              }
            }

            officeTime = totalOfficeTime - breakTime;
            totalTime += officeTime;
          }

          dateRows[date] = reportData ? (
            `${(officeTime / 3600).toFixed(2)}h${
              breakTime > 0 ? " / " + (breakTime / 3600).toFixed(2) + "h" : ""
            }`
          ) : (
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              lineHeight={1}
              sx={{ cursor: "pointer" }}
              data={JSON.stringify({ id, name, date, attendances: curAttendances })}
              onClick={openAttendanceDialog}
            >
              <MDTypography variant="button" fontWeight="medium">
                {(officeTime / 3600).toFixed(2)}h
              </MDTypography>
              {breakTime > 0 && (
                <MDTypography variant="caption">{(breakTime / 3600).toFixed(2)}h</MDTypography>
              )}
            </MDBox>
          );
        } else {
          dateRows[date] = reportData ? (
            isWeekend ? (
              "_"
            ) : (
              "A"
            )
          ) : (
            <MDBox display="flex" flexDirection="column" alignItems="center" lineHeight={1}>
              {isWeekend ? (
                <MDTypography variant="button">_</MDTypography>
              ) : (
                <MDTypography variant="button" sx={{ color: "red" }}>
                  A
                </MDTypography>
              )}
            </MDBox>
          );
        }
      }

      return reportData
        ? { name: `${name} (${userId})`, ...dateRows, total: `${(totalTime / 3600).toFixed(2)}h` }
        : {
            name: (
              <MDBox display="flex" alignItems="left" flexDirection="column" lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {name}
                </MDTypography>
                <MDTypography variant="caption">{userId}</MDTypography>
              </MDBox>
            ),
            ...dateRows,
            total: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {(totalTime / 3600).toFixed(2)}h
              </MDTypography>
            ),
          };
    });

    return { rows, columns };
  };

  const openAttendanceDialog = ({ currentTarget }) => {
    const dataJson = currentTarget.getAttribute("data");
    const data = JSON.parse(dataJson);

    setAttendanceDialog(data);
  };

  const closeAttendanceDialog = () => {
    setAttendanceDialog(null);
  };

  const downloadReport = () => {
    fetchReportData()
      .then((response) => {
        const { rows, columns } = response;

        const { to, from } = filter;

        if (rows.length > 0) {
          downloadCsv(
            rows,
            columns,
            `ZK Tech Report ${from} to ${to} - ${moment.utc(Date.now()).local().format()}.csv`
          );

          alert.show("Success", "Report has been downloaded successfully.");
        } else {
          alert.show("Failure", "No data available to download.");
        }
      })
      .catch((error) => {
        console.log("downloadReport", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      });
  };

  return (
    resourcesData && (
      <Card>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDBox>
            <MDTypography variant="h6">Report</MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              variant="h6"
              gutterBottom
              color="text"
              fontSize="small"
              onClick={downloadReport}
            >
              DOWNLOAD CSV
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox>
          <APIDataTable
            table={resourcesData}
            showTotalEntries={true}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            currentPage={page}
            totalPages={totalPages}
            totalResults={totalResults}
            fetchData={(newPage, newLimit, newFilter) => {
              if (newPage !== page) {
                setPage(newPage);
              }

              if (newLimit !== limit) {
                setLimit(newLimit);
              }
            }}
          />
        </MDBox>

        {attendanceDialog && (
          <AttendenceDialog
            open={Boolean(attendanceDialog)}
            data={attendanceDialog}
            onClose={closeAttendanceDialog}
          />
        )}
      </Card>
    )
  );
}

ReportData.defaultProps = {
  filter: null,
};

// Typechecking props for the DataTable
ReportData.propTypes = {
  filter: PropTypes.object,
};

export default ReportData;
