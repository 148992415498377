import React, { useEffect } from "react";

import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";

import MDBox from "components/MDBox";

import { Controller, useForm } from "react-hook-form";

import { FormContainer, TextFieldElement, SelectElement } from "react-hook-form-mui";

import { deviceLocation, deviceState, deviceType } from "../../../enums";

import PropTypes from "prop-types";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";

function AddDialog({ open, id, selectedType, defaultValues, onClose, onSubmit }) {
  const { control, handleSubmit, getValues, reset, setValue } = useForm();

  const handleClose = () => {
    onClose && onClose();
  };

  useEffect(() => {
    // Set the default values when the component mounts
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleFormSubmit = (data) => {
    if (selectedType === "add") {
      onSubmit && onSubmit({ ...data, selectedType });
    } else {
      const { name, ip, port, location, state, type } = data;

      let updatedData = {};

      if (name && name !== defaultValues.name) {
        updatedData["name"] = name;
      }

      if (ip && ip !== defaultValues.ip) {
        updatedData["ip"] = ip;
      }

      if (port && port !== defaultValues.port) {
        updatedData["port"] = port;
      }
      if (location && location !== defaultValues.location) {
        updatedData["location"] = location;
      }
      if (state && state !== defaultValues.state) {
        updatedData["state"] = state;
      }
      if (type && type !== defaultValues.type) {
        updatedData["type"] = type;
      }

      onSubmit && onSubmit({ ...updatedData, selectedType, id });
    }

    // reset();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <FormContainer
        control={control}
        defaultValue={defaultValues}
        onSuccess={handleSubmit(handleFormSubmit)}
      >
        <DialogTitle>{selectedType === "add" ? "Add Device" : "Edit Device"}</DialogTitle>

        {selectedType === "add" ? (
          <DialogContent>
            <DialogContentText>Please enter the required information.</DialogContentText>
            {/* <MDBox display="flex" flexDirection="row-reverse"></MDBox> */}
            <TextFieldElement
              control={control}
              margin="dense"
              name="name"
              label="Device Name"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextFieldElement
              control={control}
              margin="dense"
              name="ip"
              label="IP"
              type="text"
              fullWidth
              variant="standard"
              required
            />
            <TextFieldElement
              control={control}
              margin="dense"
              name="port"
              label="Port"
              type="number"
              fullWidth
              variant="standard"
              required
            />

            <SelectElement
              control={control}
              margin="dense"
              label="Device Location"
              name="location"
              variant="standard"
              fullWidth
              options={[deviceLocation.PECHS, deviceLocation.CLIFTON].map((keyV) => {
                return { id: keyV, label: deviceLocation.value(keyV) };
              })}
              required
            />

            <SelectElement
              control={control}
              margin="dense"
              label="Device Type"
              name="type"
              variant="standard"
              fullWidth
              options={[deviceType.PRIMARY, deviceType.SECONDARY].map((keyV) => {
                return { id: keyV, label: deviceType.value(keyV) };
              })}
              required
            />

            <SelectElement
              control={control}
              margin="dense"
              label="Device State"
              name="state"
              variant="standard"
              fullWidth
              options={[deviceState.CHECKIN, deviceState.CHECKOUT].map((keyV) => {
                return { id: keyV, label: deviceState.value(keyV) };
              })}
              required
            />
          </DialogContent>
        ) : (
          <DialogContent>
            {/* <MDBox display="flex" flexDirection="row-reverse"></MDBox>{" "} */}
            <TextFieldElement
              control={control}
              margin="dense"
              name="name"
              label="Device Name"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextFieldElement
              control={control}
              margin="dense"
              name="ip"
              label="IP"
              type="text"
              fullWidth
              variant="standard"
              required
            />
            <TextFieldElement
              control={control}
              margin="dense"
              name="port"
              label="Port"
              type="number"
              fullWidth
              variant="standard"
              required
            />
            <SelectElement
              control={control}
              margin="dense"
              label="Device Location"
              name="location"
              variant="standard"
              fullWidth
              options={[deviceLocation.PECHS, deviceLocation.CLIFTON].map((keyV) => {
                return { id: keyV, label: deviceLocation.value(keyV) };
              })}
              required
            />
            <SelectElement
              control={control}
              margin="dense"
              label="Device Type"
              name="type"
              variant="standard"
              fullWidth
              options={[deviceType.PRIMARY, deviceType.SECONDARY].map((keyV) => {
                return { id: keyV, label: deviceType.value(keyV) };
              })}
              required
            />
            <SelectElement
              control={control}
              margin="dense"
              label="Device State"
              name="state"
              variant="standard"
              fullWidth
              options={[deviceState.CHECKIN, deviceState.CHECKOUT].map((keyV) => {
                return { id: keyV, label: deviceState.value(keyV) };
              })}
              required
            />
          </DialogContent>
        )}

        {/* </MDBox> */}

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
AddDialog.defaultProps = {
  open: false,
  userId: null,
  defaultValues: {},
  onClose: null,
  onSubmit: null,
};

// Typechecking props for the DataTable
AddDialog.propTypes = {
  open: PropTypes.bool,
  selectedType: PropTypes.oneOf(["add", "edit"]).isRequired,
  userId: PropTypes.string,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddDialog;
