/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { useState, useContext } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useAlert } from "customComponents/Alert";

import AuthContext from "authContext";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const { login } = useContext(AuthContext);
  const alert = useAlert();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (data) => {
    const { email, password } = data;
    console.log("Login", email, password);
    try {
      await login({ email, password });
      window.location.reload();
    } catch (error) {
      console.log(error);
      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {/* <MDBox component="form" role="form"> */}
          <FormContainer defaultValues={{}} onSuccess={handleSubmit}>
            <MDBox mb={2}>
              <TextFieldElement
                name="email"
                type="email"
                label="Email"
                fullWidth
                required
                // renderInput={(params) => <MDInput {...params} />}
              />
            </MDBox>
            <MDBox mb={2}>
              <TextFieldElement
                name="password"
                type="password"
                label="Password"
                fullWidth
                required
                // renderInput={(params) => <MDInput {...params} />}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            {/* </MDBox> */}
          </FormContainer>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
