/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import APIDataTable from "customComponents/Tables/APIDataTable";
import { useAlert } from "customComponents/Alert";

import AddDialog from "./AddDialog";

// Data

import PropTypes from "prop-types";
import { addDevice, getDevices, deleteDevice, editDevice } from "helper";
import DashboardNavbar from "customComponents/Navbars/DashboardNavbar";
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import Footer from "customComponents/Footer";

import { deviceLocation, deviceState, deviceType } from "../../enums";
import { formatDate, isObjectEqual } from "../../utils";

function DeviceData({ device }) {
  const [menu, setMenu] = useState(null);
  const [addDialog, setAddDialog] = useState(false);
  const alert = useAlert();

  const [devicesData, setDevicesData] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [filter, setFilter] = useState({});

  const columns = [
    { Header: "name", accessor: "name", width: "20%", align: "left" },
    { Header: "ip", accessor: "ip", align: "center" },
    { Header: "port", accessor: "port", align: "center" },
    { Header: "location", accessor: "location", align: "center" },
    { Header: "state", accessor: "state", align: "center" },
    { Header: "type", accessor: "type", align: "center" },
    { Header: "last synced", accessor: "lastSynced", align: "center" },

    { Header: "action", accessor: "action", align: "center" },
  ];

  const filterOptions = [
    {
      name: "location",
      label: "Location",
      type: "select",
      options: [
        { id: "pechs", label: "PECHS" },
        { id: "clifton", label: "Clifton" },
      ],
    },
  ];

  useEffect(() => {
    fetchData();
  }, [page, limit, filter]);

  const fetchData = useCallback(async () => {
    try {
      const { search, ...reset } = filter;
      let payload = { page, limit, ...reset, sortBy: "location:asc,type:asc,state:asc" };

      if (search && search !== "") {
        payload["name"] = search;
      }

      const res = await getDevices(payload);

      const { results, totalPages, totalResults } = res.data;

      const formattedData = formatData(results);

      setDevicesData(formattedData);
      setTotalPages(totalPages);
      setTotalResults(totalResults);

      console.log(res);
    } catch (error) {
      console.log("error", error);

      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  }, [page, limit, filter]);

  const addDeviceRequest = useCallback(
    async (payload, onSuccess) => {
      try {
        await addDevice(payload);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("Add Worklog Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const editDeviceRequest = useCallback(
    async (Id, payload, onSuccess) => {
      try {
        await editDevice(Id, payload);

        fetchData();

        onSuccess && onSuccess();
        alert.show("success", "Updated Successfully");
      } catch (error) {
        console.log("Edit Worklog Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const deleteDeviceRequest = useCallback(
    async (id, onSuccess) => {
      try {
        await deleteDevice(id);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("Delete Worklog Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const formatData = (res) => {
    return res.map((value, index) => {
      const { name, ip, port, location, type, state, lastSynced } = value;

      return {
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {name}
          </MDTypography>
        ),
        ip: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {ip}
          </MDTypography>
        ),
        state: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {deviceState.value(state)}
          </MDTypography>
        ),
        type: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {deviceType.value(type)}
          </MDTypography>
        ),
        port: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {port}
          </MDTypography>
        ),
        location: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {deviceLocation.value(location)}
          </MDTypography>
        ),

        lastSynced: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {formatDate(lastSynced)}
          </MDTypography>
        ),

        action: (
          <MDTypography
            component="a"
            color="text"
            id={value.id}
            data={JSON.stringify(value)}
            onClick={openMenu}
          >
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      };
    });
  };

  const openMenu = ({ currentTarget }) => {
    setMenu(currentTarget);
  };

  const closeMenu = () => {
    setMenu(null);
  };

  const openAddDialog = (selectedType = "add", id = null, defaultValues = {}) => {
    setAddDialog({ selectedType, id, defaultValues });
  };

  const closeAddDialog = () => {
    setAddDialog(null);
  };

  const handleAddWorklog = () => {
    closeMenu();
    openAddDialog();
  };

  const handleEditWorklog = async () => {
    closeMenu();

    const dataJson = menu.getAttribute("data");
    console.log(dataJson);
    const data = JSON.parse(dataJson);

    const { port, ip, name, location, state, type } = data;
    // const projectNames = (projects ?? []).map((project) => project.name);

    openAddDialog("edit", menu.id, {
      port,
      ip,
      name,
      location,
      state,
      type,
    });
  };

  const handleDeleteWorklog = () => {
    const deviceId = menu.id;
    console.log("worklogId", deviceId);
    closeMenu();

    alert.show(
      "Remove Device",
      `Are you sure you want to delete device record?`,
      ["No", "Yes"],
      (index) => {
        if (index === 1) {
          deleteDeviceRequest(deviceId);
        }
      }
    );
  };

  const handleSubmit = (data) => {
    const { id, selectedType, ...rest } = data;

    if (selectedType === "add") {
      addDeviceRequest(rest, closeAddDialog);
    } else {
      const { name, type, ip, port, location, state } = rest;

      if (name || ip || port || location || state || type) {
        editDeviceRequest(id, rest, closeAddDialog);
      } else {
        alert.show("Edit Device", "You must have at least one key to updete");
      }
    }
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={handleEditWorklog}>Edit</MenuItem>
      <MenuItem onClick={handleDeleteWorklog}>Delete</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
            <MDBox>
              <MDTypography variant="h6">Devices</MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                variant="h6"
                gutterBottom
                color="text"
                fontSize="small"
                onClick={handleAddWorklog}
              >
                ADD DEVICE
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox>
            <APIDataTable
              table={{ rows: devicesData, columns }}
              showTotalEntries={true}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
              canSearch
              currentPage={page}
              totalPages={totalPages}
              totalResults={totalResults}
              filterOptions={filterOptions}
              fetchData={(newPage, newLimit, newFilter) => {
                if (newPage !== page) {
                  setPage(newPage);
                }

                if (newLimit !== limit) {
                  setLimit(newLimit);
                }

                if (!isObjectEqual(newFilter, filter)) {
                  if (page !== 1 || newPage !== 1) {
                    setPage(1);
                  }

                  setFilter(newFilter);
                }
              }}
            />
          </MDBox>
          {renderMenu}
          {addDialog && (
            <AddDialog
              open={Boolean(addDialog)}
              {...addDialog}
              onClose={closeAddDialog}
              onSubmit={handleSubmit}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

DeviceData.defaultProps = {
  device: null,
};

// Typechecking props for the DataTable
DeviceData.propTypes = {
  device: PropTypes.object,
};

export default DeviceData;
