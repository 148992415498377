import React, { useEffect, useState, useCallback } from "react";

import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import MDBox from "components/MDBox";

import { Controller, useForm } from "react-hook-form";

import {
  FormContainer,
  TextFieldElement,
  DateTimePickerElement,
  SelectElement,
} from "react-hook-form-mui";

import PropTypes from "prop-types";

import AutocompleteSearch from "customComponents/AutocompleteSearch";

import { getDevices } from "helper";

function AddDialog({ open, attendanceId, type, defaultValues, onClose, onSubmit }) {
  const { control, handleSubmit, reset } = useForm();

  const [devicesOptions, setDevicesOptions] = useState([]);

  const handleClose = () => {
    onClose && onClose();
  };

  useEffect(() => {
    fetchDevicesData();
  }, []);

  useEffect(() => {
    // Set the default values when the component mounts
    reset(defaultValues);
  }, [defaultValues, reset]);

  const fetchDevicesData = useCallback(async () => {
    try {
      const res = await getDevices({ limit: 20, sortBy: "type:asc,state:asc,location:asc" });

      const { results } = res.data;

      const formattedData = results.map((device) => {
        return { id: device.id, label: device.name };
      });

      setDevicesOptions(formattedData);
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const handleFormSubmit = (data) => {
    const { recordTime, employee, device } = data;

    if (type === "add") {
      onSubmit &&
        onSubmit({
          employeeId: employee.id,
          recordTime: new Date(recordTime).toISOString(),
          deviceId: device,
          verifyState: 0,
          userSn: 0,
          verifyType: 0,
          type,
        });
    } else {
      let updatedData = {};
      if (recordTime && recordTime !== defaultValues.recordTime) {
        updatedData["recordTime"] = new Date(recordTime).toISOString();
      }

      onSubmit && onSubmit({ ...updatedData, attendanceId, type });
    }

    // reset();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormContainer
        control={control}
        defaultValue={defaultValues}
        onSuccess={handleSubmit(handleFormSubmit)}
      >
        <DialogTitle>{type === "add" ? "Add Attendance" : "Edit Attendance"}</DialogTitle>

        {type === "add" ? (
          <DialogContent>
            <DialogContentText>Please enter the required information.</DialogContentText>
            <MDBox display="flex" flexDirection="row-reverse"></MDBox>

            <Controller
              control={control}
              name="employee"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <AutocompleteSearch
                  label={"Employee"}
                  refreshKey={`${Boolean(defaultValues)}`}
                  value={value}
                  apiUrl={"/v2/employees"}
                  filter={{}}
                  optionKey={"name"}
                  onOptionSelected={(resp) => {
                    onChange(resp);
                  }}
                />
              )}
            />

            <SelectElement
              control={control}
              margin="dense"
              label="Device"
              name="device"
              variant="standard"
              fullWidth
              options={devicesOptions}
              required
            />

            <DateTimePickerElement
              control={control}
              name="recordTime"
              label="Record Time"
              type="text"
              inputProps={{ margin: "dense", variant: "standard", fullWidth: true }}
              required
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <TextFieldElement
              control={control}
              margin="dense"
              name="deviceUserId"
              label="Employee Id"
              type="text"
              fullWidth
              variant="standard"
              disabled
            />
            <TextFieldElement
              control={control}
              margin="dense"
              name="employeeName"
              label="Employee Name"
              type="text"
              fullWidth
              variant="standard"
              disabled
            />
            <DateTimePickerElement
              control={control}
              name="recordTime"
              label="Record Time"
              type="text"
              fullWidth
              inputProps={{ margin: "dense", variant: "standard", fullWidth: true }}
              required
            />
          </DialogContent>
        )}

        {/* </MDBox> */}

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
AddDialog.defaultProps = {
  open: false,
  userId: null,
  defaultValues: {},
  onClose: null,
  onSubmit: null,
};

// Typechecking props for the DataTable
AddDialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.oneOf(["add", "edit"]).isRequired,
  userId: PropTypes.string,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddDialog;
