/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components

import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import APIDataTable from "customComponents/Tables/APIDataTable";
import { useAlert } from "customComponents/Alert";

import PropTypes from "prop-types";
import {
  getShifts,
  getEmployees,
  editEmployee,
  deleteEmployee,
  restoreEmployee,
  sendEmployeeReport,
} from "helper/index";

import AddDialog from "layouts/employee/AddDialog";
import DashboardNavbar from "customComponents/Navbars/DashboardNavbar";
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import Footer from "customComponents/Footer";
import { isObjectEqual, formatDate } from "../../utils";
import ReportDialog from "./ReportDialog";

function EmployeeData({ employee }) {
  const alert = useAlert();

  const [menu, setMenu] = useState(null);
  const [addDialog, setAddDialog] = useState(false);
  const [reportDialog, setReportDialog] = useState(false);
  const [resourcesData, setResourcesData] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [filter, setFilter] = useState({});
  const [showDeleted, setShowDeleted] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const columns = [
    { Header: "name", accessor: "name", width: "20%", align: "left" },
    { Header: "userId", accessor: "userId", align: "center" },
    { Header: "cardno", accessor: "cardno", align: "center" },
    { Header: "shift", accessor: "shift", align: "center" },
    { Header: "role", accessor: "role", align: "center" },
    { Header: "Daily Reports", accessor: "dailyReports", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    fetchShiftsData();
  }, []);

  const fetchShiftsData = useCallback(async () => {
    try {
      const res = await getShifts({ limit: 20, sortBy: "isDefault:desc,createdAt:desc" });

      const { results } = res.data;

      const formattedData = results.map((shift) => {
        return { id: shift.id, label: shift.title };
      });

      setFilterOptions([{ name: "shift", label: "Shift", type: "select", options: formattedData }]);
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, limit, filter, showDeleted]);

  const fetchData = useCallback(async () => {
    try {
      const { search, sortBy, ...reset } = filter;
      const uSortBy = sortBy
        ? sortBy.includes("role")
          ? sortBy.replace("role", "employeeRole")
          : sortBy
        : "name:asc";
      let payload = { page, limit, ...reset, sortBy: uSortBy };

      if (search && search !== "") {
        payload["search"] = search;
      }

      if (uSortBy.includes("userId") || uSortBy.includes("cardno")) {
        payload["collation"] = "locale:en_US,numericOrdering:true";
      }

      payload["deleted"] = showDeleted;

      const res = await getEmployees(payload);

      const { results, totalPages, totalResults } = res.data;

      const formattedData = formatData(results);

      setResourcesData(formattedData);
      setTotalPages(totalPages);
      setTotalResults(totalResults);
    } catch (error) {
      console.log("error", error);

      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  }, [page, limit, filter, showDeleted]);

  const editEmployeeRequest = useCallback(
    async (id, payload, onSuccess) => {
      try {
        await editEmployee(id, payload);

        fetchData();

        onSuccess && onSuccess();
        alert.show("Employee", "Updated Successfully");
      } catch (error) {
        console.log("Edit Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter, showDeleted]
  );

  const deleteRequest = useCallback(
    async (employeeId, onSuccess) => {
      try {
        await deleteEmployee(employeeId);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log(error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter, showDeleted]
  );

  const restoreRequest = useCallback(
    async (employeeId, onSuccess) => {
      try {
        await restoreEmployee(employeeId);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log(error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter, showDeleted]
  );

  const sendEmployeeReportRequest = useCallback(
    async (id, payload, onSuccess) => {
      try {
        await sendEmployeeReport(id, payload);

        onSuccess && onSuccess();
        alert.show("Report", "Sent Successfully");
      } catch (error) {
        console.log("Edit Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter, showDeleted]
  );

  const formatData = (res) => {
    return res.map((value, index) => {
      const {
        name,
        email,
        cardno,
        userId,
        shift,
        dailyReports,
        slackProfile,
        isDeleted,
        employeeRole,
      } = value;

      // const { realName, image_72 } = slackProfile;
      console.log(`value`, value);

      const color = isDeleted ? "error" : "inherit";

      const role =
        employeeRole === "adminEmployee"
          ? "ADMIN"
          : employeeRole === "subAdminEmployee"
          ? "SUB ADMIN"
          : "DEFAULT";

      const roleColor =
        employeeRole === "adminEmployee" || employeeRole === "subAdminEmployee"
          ? "primary"
          : "text";

      return {
        name: email ? (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            {slackProfile && slackProfile.image_72 && (
              <MDAvatar src={slackProfile.image_72} name={slackProfile.realName} size="sm" />
            )}
            <MDBox lineHeight={1} ml={slackProfile ? 1 : 0}>
              <MDTypography display="block" variant="button" fontWeight="medium" color={color}>
                {name}
              </MDTypography>
              <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
          </MDBox>
        ) : (
          <MDTypography variant="caption" color={color} fontWeight="medium">
            {name}
          </MDTypography>
        ),
        userId: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {userId}
          </MDTypography>
        ),

        cardno: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {cardno}
          </MDTypography>
        ),
        shift: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {shift.title}
          </MDTypography>
        ),
        role: (
          <MDTypography variant="caption" color={roleColor} fontWeight="medium">
            {role}
          </MDTypography>
        ),
        dailyReports: (
          <MDBox ml={-1}>
            {dailyReports && email ? (
              <MDBadge
                badgeContent={"active"}
                color={
                  employeeRole === "adminEmployee" || employeeRole === "subAdminEmployee"
                    ? "primary"
                    : "success"
                }
                variant="gradient"
                size="sm"
              />
            ) : (
              <MDBadge badgeContent="inactive" color="dark" variant="gradient" size="sm" />
            )}
          </MDBox>
        ),
        action: (
          <MDTypography
            component="a"
            color="text"
            id={value.id}
            data={JSON.stringify(value)}
            onClick={openMenu}
          >
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      };
    });
  };

  const openMenu = ({ currentTarget }) => {
    setMenu(currentTarget);
  };

  const closeMenu = () => {
    setMenu(null);
  };

  const openAddDialog = (type = "add", employeeId = null, defaultValues = {}) => {
    setAddDialog({ type, employeeId, defaultValues });
  };

  const closeAddDialog = () => {
    setAddDialog(null);
  };

  const openReportDialog = (defaultValues = {}) => {
    setReportDialog({ defaultValues });
  };

  const closeReportDialog = () => {
    setReportDialog(null);
  };

  const handleEdit = async () => {
    closeMenu();

    const dataJson = menu.getAttribute("data");
    const data = JSON.parse(dataJson);

    const { userId, name, email, cardno, uid, shift, dailyReports, employeeRole } = data;

    openAddDialog("edit", menu.id, {
      userId,
      name,
      email,
      cardno,
      uid,
      shift: shift.id,
      dailyReports,
      employeeRole,
    });
  };

  const handleReport = async () => {
    // let defaultValues = {};

    // if (menu.id !== null) {
    //   const dataJson = menu.getAttribute("data");
    //   const data = JSON.parse(dataJson);

    //   const { type, id, userId, name, email } = data;

    //   if (type !== "HEADER") {
    //     defaultValues["employee"] = { id, userId, name, email };
    //   }
    // }

    closeMenu();

    openReportDialog();
  };

  const handleDelete = () => {
    const employeeId = menu.id;
    console.log("employeeId", employeeId);
    closeMenu();

    alert.show(
      "Delete Employee",
      `Are you sure you want to delete employee?`,
      ["No", "Yes"],
      (index) => {
        if (index === 1) {
          deleteRequest(employeeId);
        }
      }
    );
  };

  const handleRestore = () => {
    const employeeId = menu.id;
    console.log("employeeId", employeeId);
    closeMenu();

    alert.show(
      "Restore Employee",
      `Are you sure you want to restore employee?`,
      ["No", "Yes"],
      (index) => {
        if (index === 1) {
          restoreRequest(employeeId);
        }
      }
    );
  };

  const handleShowHideDeleted = () => {
    closeMenu();

    setShowDeleted(!showDeleted);
    setPage(1);
  };

  const handleSubmit = (data) => {
    const { employeeId, type, ...rest } = data;

    console.log("rest", rest);

    if (type === "add") {
      // Not allowed
      // const { type, userId, name, cardno, uid, shift } = data;
      // addEmployeeRequest(
      //   {
      //     userId,
      //     name,
      //     cardno,
      //     uid,
      //   },
      //   closeAddDialog
      // );
    } else {
      if (
        rest.name ||
        rest.shift ||
        rest.email ||
        rest.dailyReports !== undefined ||
        rest.employeeRole !== undefined
      ) {
        editEmployeeRequest(employeeId, rest, closeAddDialog);
      } else {
        alert.show("Edit Employee", "You must have at least one key to updete");
      }
    }
  };

  const handleSendReport = (data) => {
    const { employeeId, date } = data;

    const formattedDate = formatDate(date, "YYYY-MM-DD");

    sendEmployeeReportRequest(employeeId, { date: formattedDate }, closeReportDialog);
  };

  const renderMenu = () => {
    const data = menu ? JSON.parse(menu.getAttribute("data")) : {};
    const { type, isDeleted } = data;

    const menuItems =
      menu === null
        ? []
        : type === "HEADER"
        ? [
            <MenuItem onClick={handleReport} key={0}>
              Send Report
            </MenuItem>,
            <MenuItem onClick={handleShowHideDeleted} key={1}>
              Show/Hide Deleted
            </MenuItem>,
          ]
        : isDeleted === true
        ? [<MenuItem onClick={handleRestore}>Restore</MenuItem>]
        : [
            // <MenuItem onClick={handleReport} key={0}>
            //   Send Report
            // </MenuItem>,
            <MenuItem onClick={handleEdit} key={1}>
              Edit
            </MenuItem>,
            <MenuItem onClick={handleDelete} key={2}>
              Delete
            </MenuItem>,
          ];

    return (
      <Menu
        id="simple-menu"
        anchorEl={menu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
      >
        {menuItems}
      </Menu>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
            <MDBox>
              <MDTypography variant="h6">
                {showDeleted ? "Employees - Deleted" : "Employees"}
              </MDTypography>
            </MDBox>
            {
              <MDBox>
                <MDTypography
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  component="a"
                  gutterBottom
                  color="text"
                  // fontSize="small"
                  data={JSON.stringify({ type: "HEADER" })}
                  onClick={openMenu}
                >
                  <Icon>more_vert</Icon>
                </MDTypography>
              </MDBox>
            }
          </MDBox>

          <MDBox>
            <APIDataTable
              table={{ rows: resourcesData, columns }}
              showTotalEntries={true}
              sortingData={{ name: true, userId: true, cardno: true, shift: true, role: true }}
              noEndBorder
              entriesPerPage={false}
              canSearch
              currentPage={page}
              totalPages={totalPages}
              totalResults={totalResults}
              filterOptions={filterOptions}
              fetchData={(newPage, newLimit, newFilter) => {
                if (newPage !== page) {
                  setPage(newPage);
                }

                if (newLimit !== limit) {
                  setLimit(newLimit);
                }

                if (!isObjectEqual(newFilter, filter)) {
                  if (page !== 1 || newPage !== 1) {
                    setPage(1);
                  }

                  setFilter(newFilter);
                }
              }}
            />
          </MDBox>

          {renderMenu()}
          {addDialog && (
            <AddDialog
              open={Boolean(addDialog)}
              {...addDialog}
              onClose={closeAddDialog}
              onSubmit={handleSubmit}
            />
          )}

          {reportDialog && (
            <ReportDialog
              open={Boolean(reportDialog)}
              {...reportDialog}
              onClose={closeReportDialog}
              onSubmit={handleSendReport}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

EmployeeData.defaultProps = {
  employee: null,
};

// Typechecking props for the DataTable
EmployeeData.propTypes = {
  employee: PropTypes.object,
};

export default EmployeeData;
