import axios from "axios";
import { API_URL } from "../config";

const jwtClient = axios.create({});

let refreshingFunc = undefined;

async function refreshToken() {
  const authData = JSON.parse(localStorage.getItem("tokens"));

  if (authData && authData.refresh && authData.refresh.token) {
    const payload = {
      refreshToken: authData.refresh.token,
    };

    try {
      const apiResponse = await axios.post(`${API_URL}/v2/auth/refresh-tokens`, payload);

      const { data } = apiResponse;

      if (data) {
        localStorage.setItem("tokens", JSON.stringify(data));
        // error.config.headers["Authorization"] = `bearer ${data.access.token}`;
      } else {
        refreshPage();

        localStorage.removeItem("tokens");
      }

      return data;
    } catch (error) {
      localStorage.removeItem("tokens");

      refreshPage();

      return Promise.reject(error);
    }
  }
}

function refreshPage() {
  window.location.reload(false);
}

jwtClient.interceptors.request.use((config) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  config.headers["Authorization"] = `bearer ${
    tokens && tokens.access && tokens.access.token ? tokens.access.token : ""
  }`;
  return config;
});

jwtClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error && error.response && error.response.status === 401) {
      if (!refreshingFunc) {
        refreshingFunc = refreshToken();
      }

      try {
        const data = await refreshingFunc;

        if (data) {
          error.config.headers["Authorization"] = `bearer ${data.access.token}`;

          return axios(error.config);
        } else {
          // logout user
          console.log("logout user");
          return Promise.resolve({ data: null }); //Promise.reject(error);
        }
      } catch (refreshTokenError) {
        // logout user
        console.log("logout user");
        return Promise.resolve({ data: null });
        Promise.reject(refreshTokenError);
      } finally {
        refreshingFunc = undefined;
      }

      /*
      const authData = JSON.parse(localStorage.getItem("tokens"));

      if (authData && authData.refresh && authData.refresh.token) {
        const payload = {
          refreshToken: authData.refresh.token,
        };

        try {
          const apiResponse = await axios.post(`${API_URL}/v2/auth/refresh-tokens`, payload);

          const { data } = apiResponse;

          if (data) {
            localStorage.setItem("tokens", JSON.stringify(data));
            error.config.headers["Authorization"] = `bearer ${data.access.token}`;

            return axios(error.config);
          } else {
            localStorage.removeItem("tokens");
            return Promise.reject(error);
          }
        } catch (err) {
          console.log("refresh-tokens error:", err);

          localStorage.removeItem("tokens");

          return Promise.reject(err);
        }
      } else {
        localStorage.removeItem("tokens");
        return Promise.reject(error);
      }
      */
    } else {
      return Promise.reject(error);
    }
  }
);

export default jwtClient;
