import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Autocomplete, CircularProgress } from "@mui/material";

import { getRequest } from "helper/index";
import MDInput from "components/MDInput";
import { API_URL } from "../../config";

function AutocompleteSearch({
  apiUrl,
  filter,
  label,
  value,
  refreshKey,
  disabled,
  optionKey,
  onOptionSelected,
  multiple,
  fullWidth,
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  const onChangeHandle = async (curValue) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(curValue);

    let payload = { page: 1, limit: 10, ...filter }; // static limit for now
    payload[optionKey] = curValue;

    try {
      setLoading(true);

      const res = await getRequest(`${API_URL}${apiUrl}`, payload);

      const { results } = res.data;

      setLoading(false);
      setOptions(results);
    } catch (error) {
      console.log("getRequest", error);

      setOptions([]);
      setLoading(false);
    }
  };

  return (
    <Autocomplete
      open={open}
      multiple={multiple}
      key={refreshKey}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option[optionKey] === value[optionKey]}
      getOptionLabel={(option) => option[optionKey]}
      options={options}
      loading={loading && open}
      fullWidth={fullWidth}
      disabled={disabled}
      onChange={(event, value) => {
        if (event && (event.type === "keydown" || event.type === "click")) {
          // console.log("onChange", event, value);
          // const res =
          //   value && value !== "" ? options.find((option) => option[optionKey] === value) : null;
          onOptionSelected && onOptionSelected(value);
        }
      }}
      // onInputChange={(event, value) => {
      //   if (event && (event.type === "keydown" || event.type === "click")) {
      //     console.log("onInputChange", value);
      //     const res =
      //       value && value !== "" ? options.find((option) => option[optionKey] === value) : null;
      //     onOptionSelected && onOptionSelected(res);
      //   }
      // }}
      renderInput={(params) => (
        <MDInput
          {...params}
          label={label}
          value={value}
          variant="standard"
          sx={{ background_color: "#ffffff!important", overflow: "visible", mt: 1, mb: 1 }}
          onChange={(ev) => {
            // dont fire API if the user delete or not entered anything
            if (ev.target.value !== "" && ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading && open ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

// Setting default values for the props
AutocompleteSearch.defaultProps = {
  onOptionSelected: null,
  label: "Name",
  disabled: false,
  refreshKey: "key",
  multiple: false,
  fullWidth: true,
  filter: {},
};

// Typechecking props for the DataTable
AutocompleteSearch.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  filter: PropTypes.object,
  optionKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  refreshKey: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onOptionSelected: PropTypes.func,
};

export default AutocompleteSearch;
