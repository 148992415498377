/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import APIDataTable from "customComponents/Tables/APIDataTable";
import { useAlert } from "customComponents/Alert";

import { deviceLocation, deviceState, deviceType } from "../../enums";
import { formatDate, isObjectEqual } from "../../utils";

// Data

import PropTypes from "prop-types";
import AddDialog from "layouts/attendance/AddDialog";
import BulkImportDialog from "layouts/attendance/BulkImportDialog";
import {
  addAttendance,
  getAttendances,
  deleteAttendance,
  editAttendance,
  bulkImportAttendance,
} from "helper";
import DashboardNavbar from "customComponents/Navbars/DashboardNavbar";
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import Footer from "customComponents/Footer";

function AttendanceData({ attendance }) {
  const [menu, setMenu] = useState(null);
  const [addDialog, setAddDialog] = useState(false);
  const [bulkImportDialog, setBulkImportDialog] = useState(false);

  const alert = useAlert();

  const [attendancesData, setAttendancesData] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [filter, setFilter] = useState({});

  const columns = [
    { Header: "employeeName", accessor: "employeeName", width: "20%", align: "left" },
    { Header: "recordTime", accessor: "recordTime", align: "center" },
    { Header: "state", accessor: "state", align: "center" },
    { Header: "location", accessor: "location", align: "center" },
    { Header: "type", accessor: "type", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const filterOptions = [
    { name: "from", label: "From", type: "date" },
    { name: "to", label: "To", type: "date" },
  ];

  useEffect(() => {
    fetchData();
  }, [page, limit, filter]);

  const fetchData = useCallback(async () => {
    try {
      let payload = { page, limit, sortBy: "recordTime:desc", ...filter };

      const res = await getAttendances(payload);

      const { results, totalPages, totalResults } = res.data;

      const formattedData = formatData(results);

      setAttendancesData(formattedData);
      setTotalPages(totalPages);
      setTotalResults(totalResults);
    } catch (error) {
      console.log("error", error);

      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  }, [page, limit, filter]);

  const addAttendanceRequest = useCallback(
    async (payload, onSuccess) => {
      try {
        await addAttendance(payload);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("Add Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const editAttendanceRequest = useCallback(
    async (Id, payload, onSuccess) => {
      try {
        await editAttendance(Id, payload);

        fetchData();

        onSuccess && onSuccess();
        alert.show("success", "Updated Successfully");
      } catch (error) {
        console.log("Edit Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const deleteAttendanceRequest = useCallback(
    async (Id, onSuccess) => {
      try {
        await deleteAttendance(Id);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("Delete Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const bulkImportRequest = useCallback(
    async (file, onSuccess) => {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const resp = await bulkImportAttendance(formData);

        fetchData();

        onSuccess && onSuccess();

        console.log("resp", resp);

        const { data } = resp;

        alert.show("Bulk import", `File imported successfully with total Records: ${data.count}`);
      } catch (error) {
        console.log("Bulk import Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        if (message.includes("invalidRecors")) {
          const { invalidRecors } = JSON.parse(message);
          console.log(invalidRecors, invalidRecors);

          const reduceMsg = invalidRecors.reduce((preV, curV) => {
            return `${preV}\n${curV}`;
          });

          alert.show("Invalid records", reduceMsg);
        } else {
          alert.show(title, message);
        }
      }
    },
    [page, limit, filter]
  );

  const formatData = (res) => {
    return res.map((value, index) => {
      const { recordTime, device, employee } = value;

      return {
        employeeName: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {employee?.name}
          </MDTypography>
        ),
        recordTime: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {formatDate(recordTime)}
          </MDTypography>
        ),
        state: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {deviceState.value(device?.state)}
          </MDTypography>
        ),
        location: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {deviceLocation.value(device?.location)}
          </MDTypography>
        ),
        type: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {deviceType.value(device?.type)}
          </MDTypography>
        ),
        action: (
          <MDTypography
            component="a"
            color="text"
            id={value.id}
            data={JSON.stringify(value)}
            onClick={openMenu}
          >
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      };
    });
  };

  const openMenu = ({ currentTarget }) => {
    setMenu(currentTarget);
  };

  const closeMenu = () => {
    setMenu(null);
  };

  const openAddDialog = (type = "add", attendanceId = null, defaultValues = {}) => {
    setAddDialog({ type, attendanceId, defaultValues });
  };

  const closeAddDialog = () => {
    setAddDialog(null);
  };

  const openBulkImportDialog = () => {
    setBulkImportDialog({});
  };

  const closeBulkImportDialog = () => {
    setBulkImportDialog(null);
  };

  const handleAdd = () => {
    closeMenu();
    openAddDialog();
  };

  const handleBulkImport = () => {
    closeMenu();
    openBulkImportDialog();
  };

  const handleEdit = async () => {
    closeMenu();

    const dataJson = menu.getAttribute("data");
    console.log(dataJson);
    const data = JSON.parse(dataJson);

    const { deviceUserId, employeeName, recordTime } = data;
    // const projectNames = (projects ?? []).map((project) => project.name);

    openAddDialog("edit", menu.id, {
      deviceUserId,
      employeeName,
      recordTime,
    });
  };

  const handleDelete = () => {
    const attendanceId = menu.id;
    console.log("attendanceId", attendanceId);
    closeMenu();

    alert.show(
      "Remove Employee Attendance",
      `Are you sure you want to delete attendance record?`,
      ["No", "Yes"],
      (index) => {
        if (index === 1) {
          deleteAttendanceRequest(attendanceId);
        }
      }
    );
  };

  const handleSubmit = (data) => {
    const { attendanceId, type, ...rest } = data;

    if (type === "add") {
      console.log("data", data);
      addAttendanceRequest(rest, closeAddDialog);
    } else {
      if (rest.recordTime) {
        editAttendanceRequest(attendanceId, rest, closeAddDialog);
      } else {
        alert.show("Edit Attendance", "You must have at least one key to updete");
      }
    }
  };

  const handleBulkImportSubmit = (data) => {
    console.log("handleBulkImportSubmit", data);
    const { file } = data;

    bulkImportRequest(file, closeBulkImportDialog);
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={handleDelete}>Delete</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
            <MDBox>
              <MDTypography variant="h6">Attendance</MDTypography>
            </MDBox>
            <MDBox display="flex">
              <MDTypography
                sx={{ cursor: "pointer", fontWeight: "bold", marginRight: "7px" }}
                variant="h6"
                gutterBottom
                color="text"
                fontSize="small"
                onClick={handleBulkImport}
              >
                BULK IMPORT
              </MDTypography>

              <MDTypography
                sx={{ cursor: "pointer", fontWeight: "bold", marginLeft: "7px" }}
                variant="h6"
                gutterBottom
                color="text"
                fontSize="small"
                onClick={handleAdd}
              >
                ADD ATTENDANCE
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox>
            <APIDataTable
              table={{ rows: attendancesData, columns }}
              showTotalEntries={true}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
              canSearch
              currentPage={page}
              totalPages={totalPages}
              totalResults={totalResults}
              filterOptions={filterOptions}
              fetchData={(newPage, newLimit, newFilter) => {
                if (newPage !== page) {
                  setPage(newPage);
                }

                if (newLimit !== limit) {
                  setLimit(newLimit);
                }

                if (!isObjectEqual(newFilter, filter)) {
                  if (page !== 1 || newPage !== 1) {
                    setPage(1);
                  }

                  setFilter(newFilter);
                }
              }}
            />
          </MDBox>

          {renderMenu}
          {addDialog && (
            <AddDialog
              open={Boolean(addDialog)}
              {...addDialog}
              onClose={closeAddDialog}
              onSubmit={handleSubmit}
            />
          )}

          {bulkImportDialog && (
            <BulkImportDialog
              open={Boolean(bulkImportDialog)}
              {...bulkImportDialog}
              onClose={closeBulkImportDialog}
              onSubmit={handleBulkImportSubmit}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

AttendanceData.defaultProps = {
  attendance: null,
};

// Typechecking props for the DataTable
AttendanceData.propTypes = {
  attendance: PropTypes.object,
};

export default AttendanceData;
