import React, { useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import MDBox from "components/MDBox";

import { Controller, useForm } from "react-hook-form";

import { FormContainer, DatePickerElement } from "react-hook-form-mui";

import PropTypes from "prop-types";

import AutocompleteSearch from "customComponents/AutocompleteSearch";

function ReportDialog({ open, defaultValues, onClose, onSubmit }) {
  const { control, handleSubmit, reset } = useForm();

  const handleClose = () => {
    onClose && onClose();
  };

  useEffect(() => {}, []);

  useEffect(() => {
    // Set the default values when the component mounts
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleFormSubmit = (data) => {
    const { date, employee } = data;

    onSubmit &&
      onSubmit({
        employeeId: employee.id,
        date: new Date(date),
      });

    // reset();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormContainer
        control={control}
        defaultValue={defaultValues}
        onSuccess={handleSubmit(handleFormSubmit)}
      >
        <DialogTitle>{"Send Report"}</DialogTitle>

        <DialogContent>
          <DialogContentText>Please enter the required information.</DialogContentText>
          <MDBox display="flex" flexDirection="row-reverse"></MDBox>

          <Controller
            control={control}
            name="employee"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <AutocompleteSearch
                label={"Employee"}
                refreshKey={`${Boolean(defaultValues)}`}
                value={value}
                apiUrl={"/v2/employees"}
                filter={{}}
                optionKey={"name"}
                onOptionSelected={(resp) => {
                  onChange(resp);
                }}
              />
            )}
          />

          <DatePickerElement
            control={control}
            name="date"
            label="Date"
            type="text"
            inputProps={{ margin: "dense", variant: "standard", fullWidth: true }}
            required
          />
        </DialogContent>

        {/* </MDBox> */}

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
ReportDialog.defaultProps = {
  open: false,
  defaultValues: {},
  onClose: null,
  onSubmit: null,
};

// Typechecking props for the DataTable
ReportDialog.propTypes = {
  open: PropTypes.bool,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ReportDialog;
