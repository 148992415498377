/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import PropTypes from "prop-types";

// @mui material components

import { Button, Icon, IconButton } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useForm } from "react-hook-form";

import { FormContainer, TextFieldElement, SelectElement } from "react-hook-form-mui";

function Filter({ onSubmit, filterOptions }) {
  const defaultValues = {};

  const { control, reset, handleSubmit } = useForm();

  const [isOpen, setIsOpen] = useState();

  const handleFormSubmit = (data) => {
    onSubmit && onSubmit(data);
  };

  const handleResetButton = () => {
    reset();
    setIsOpen(false);

    onSubmit && onSubmit(null);
  };

  const handleFilterButton = () => {
    setIsOpen(true);
  };

  return (
    <MDBox display="flex" flex={1} alignItems="center">
      {isOpen ? (
        <FormContainer
          FormProps={{
            style: { display: "flex", flex: 1, flexDirection: "column" },
          }}
          control={control}
          defaultValue={defaultValues}
          onSuccess={handleSubmit(handleFormSubmit)}
        >
          <MDBox display="flex" flexDirection="row" justifyContent="left">
            {filterOptions.map((option) => {
              const { type, ...rest } = option;

              return type === "select" ? (
                <SelectElement
                  control={control}
                  key={option.name}
                  margin="dense"
                  {...rest}
                  variant="standard"
                  fullWidth
                  required
                  sx={{ paddingRight: 2, width: "25%" }}
                />
              ) : (
                <TextFieldElement
                  InputLabelProps={
                    option.type === "date" ||
                    option.type === "time" ||
                    option.type === "datetime" ||
                    option.type === "month"
                      ? { shrink: true }
                      : {}
                  }
                  {...rest}
                  type={type}
                  key={option.name}
                  control={control}
                  margin="dense"
                  variant="standard"
                  required
                  sx={{ paddingRight: 2, width: "25%" }}
                />
              );
            })}

            <Button onClick={handleResetButton}>Reset</Button>
            <Button type="submit">Apply</Button>
          </MDBox>
        </FormContainer>
      ) : (
        <IconButton
          size="small"
          aria-label="filter_list"
          color="inherit"
          onClick={handleFilterButton}
        >
          <Icon fontSize="small">filter_list</Icon>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Filter
          </MDTypography>
        </IconButton>
      )}
    </MDBox>
  );
}

// Setting default values for the props
Filter.defaultProps = {};

// Typechecking props for the DataTable
Filter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Filter;
